import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContexts';
import axios from '../../api/axios';
import { Spinner } from 'react-bootstrap';
import Image from '../message/Image';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import DeleteModal from '../message/DeleteModal';

function nl2br(str, is_xhtml = false) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export default function Dashboard() {
    const { connectionState, sessionToken, setUserData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [messageUserData, setMessageUserData] = useState([]);
    const [chatMessage, setChatMessage] = useState([]);
    const [chatdata, setChatdata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dashboardDetail, setDashboardDetail] = useState({});
    const [popup, setPopup] = useState(false);
    const [messageId, setMessageId] = useState("");
    const [userDetail, setUserDetail] = useState(null);
    const [likes, setLikes] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [dialogid, setDialogid] = useState("");
    const [loader, setLoader] = useState(false);
    const fileRef = useRef();
    const QB = window.QB;
    
    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userDetail?.vendor_id,
        };
        axios.get('/user/profile', { params: payload })
            .then((response) => {
                setUserData(response.data);
                setUserDetail(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, []);

    useEffect(() => {

        let payload = {
            api_token: token,
            vendor_id: userDetail?.vendor_id,
            //     nc: "1694788476112"

        };
        axios.get("/comment-board/menu-item", { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                setDashboardDetail(response.data);
                console.log("********response.data", response.data);
            }).catch(err => {
                console.log("err ", err);
            });

    }, [token, userDetail]);

    useEffect(() => {

        if (sessionToken && connectionState && dashboardDetail.dialog_id) {
            chatMessages(dashboardDetail);
            var dialog = dashboardDetail.dialog_id;

            var dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialog);

            try {
                QB.chat.muc.join(dialogJid, function (error, result) {
                    if (result) {
                        setDialogid(result.dialogId);
                    }
                });
            } catch (e) {
                if (e.name === 'ChatNotConnectedError') {
                    console.log("****not connected", e);
                }
            }
        }

    }, [sessionToken, dashboardDetail.dialog_id, connectionState]);

    useEffect(() => {
        QB.chat.onMessageListener = onMessage;

        function onMessage(userId, message) {
            chatMessages(message.extension.dialog_id);
        }
    }, []);

    const chatMessages = (data) => {
        let dialogId = data.dialog_id;
        let params = {
            token: sessionToken,
            sort_desc: 'date_sent',
            chat_dialog_id: dialogId,
            limit: 1000,
            skip: 0
        };
        if (dialogId) {
            QB.chat.message.list(params, function (error, messages) {
                if (error) {
                    console.log("******* chat error", error);
                }
                else {
                    setChatMessage(messages.items);
                    const senderId = messages?.items.map((item, index) => { return item.sender_id });
                    let Ids = [];
                    let filteredArray = senderId.filter((item,
                        index) => senderId.indexOf(item) === index);
                    let finded = filteredArray.find(item => item == userDetail?.chat_credentials?.qb_id);
                    if (finded) {
                        Ids.push(...filteredArray);
                    } else {
                        Ids.push(...filteredArray, userDetail?.chat_credentials?.qb_id);
                    }
                    getUserDetails(Ids);
                    setLoading(false);
                    getLikes(dialogId);
                }
            });
        }
    };

    const getUserDetails = (data) => {

        let payload = {
            api_token: token,
            vendor_id: userDetail?.vendor_id,
        };
        var formData = new FormData();
        if (data) {
            data.map((item, index) => {
                return formData.append(`qb_ids[${index}]`, item);
            })
        }
        axios.post('/users', formData, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setMessageUserData(Object.values(response.data));
                }

            }).catch(err => {
                console.log("******err ", err);
            });
    };


    const sendMessage = (data) => {
        let dialog_id = dialogid;
        console.log("******dialog", dialog_id, data);
        let inputFile = data.file;
        if (inputFile) {
            let fileParams = {
                name: inputFile.name,
                file: inputFile,
                type: inputFile.type,
                size: inputFile.size,
                public: false
            };
            QB.content.createAndUpload(fileParams, function (error, result) {
                if (!error) {
                    var message = {
                        token: sessionToken,
                        type: "groupchat",
                        body: data.message,
                        extension: {
                            save_to_history: 1,
                            dialog_id: dialog_id,
                            attachments: [{ id: result.id, type: result.content_type, uid: result.uid }]
                        }
                    };
                    let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialog_id);

                    try {
                        message.id = QB.chat.send(dialogJid, message);
                        if (message.id) {
                            let updatedMessage = [];
                            let item = { _id: message.id, message: message.body, sender_id: userDetail?.chat_credentials?.qb_id, attachments: [{ id: result.id, type: result.content_type, uid: result.uid }] };
                            updatedMessage.push(item, ...chatMessage);
                            setChatMessage(updatedMessage);
                            setLoader(false);
                            setImageUrl(null);
                        }
                    } catch (e) {

                        if (e.name === 'ChatNotConnectedError') {
                            console.log("*******ChatNotConnectedError");
                        }
                    }
                }
                else {
                    console.log("*****error", error);
                }
            });
        } else {
            let dialog_id = dialogid;
            let message = {
                token: sessionToken,
                type: "groupchat",
                body: data.message,
                extension: {
                    save_to_history: 1,
                    dialog_id: dialog_id
                },
                markable: 1
            };

            let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialog_id);


            try {
                message.id = QB.chat.send(dialogJid, message);
                if (message.id) {
                    let updatedMessage = [];
                    let item = { _id: message.id, message: message.body, sender_id: userDetail?.chat_credentials?.qb_id, attachments: [] };
                    updatedMessage.push(item, ...chatMessage);
                    setChatMessage(updatedMessage);
                    setLoader(false);
                    setImageUrl(null);

                }
            } catch (e) {
                if (e.name === 'ChatNotConnectedError') {
                    console.log("******error", e);
                    // not connected to chat
                }
            }
        }


    };
    useEffect(() => {
        let items = [];
        chatMessage.map(item => {
            const matchedProfile = messageUserData.find(data => data.chat_credentials.qb_id == item.sender_id);
            return items.push({ message: item, user: matchedProfile });
        });
        setChatdata(items);
    }, [chatMessage, messageUserData]);


    const likeMessage = (data) => {
        let findedmessage = likes.find(item => item.message_qb_id === data._id);
        if (findedmessage && findedmessage.liked_by_me === true) {
            let items = [];
            let removeKey = likes.filter((item) => {
                return item.message_qb_id !== data._id;

            });
            if (findedmessage.total_likes > 0) {
                let filteredMessage = {
                    total_likes: findedmessage.total_likes - 1, message_qb_id: findedmessage.message_qb_id, liked_by_me: false
                };
                items.push(...removeKey, filteredMessage);
            }
            setLikes(items);
        };
        if (findedmessage && findedmessage.liked_by_me === false) {
            let items = [];
            let removeKey = likes.filter((item) => {
                return item.message_qb_id !== data._id;

            });
            if (findedmessage.total_likes > 0) {
                let filteredMessage = {
                    total_likes: findedmessage.total_likes + 1, message_qb_id: findedmessage.message_qb_id, liked_by_me: true
                };
                items.push(...removeKey, filteredMessage);
            }
            setLikes(items);
        };
        if (!findedmessage) {
            let items = [];
            let removeKey = likes.filter((item) => {
                return item.message_qb_id !== data._id;
            });
            let filteredMessage = {
                total_likes: 1, message_qb_id: data._id, liked_by_me: true
            };
            items.push(...removeKey, filteredMessage);
            setLikes(items);
        };

        let payload = {
            api_token: token,
            vendor_id: userDetail?.vendor_id,
        };
        var formData = new FormData();
        formData.append("message_qb_id", data._id);
        formData.append("dialog_qb_id", data.chat_dialog_id);
        formData.append("vendor_id", userDetail?.vendor_id);
        axios.post('/qb/toggle-like', formData, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    getLikes();
                }

            }).catch(err => {
                console.log("******err ", err);
            });
    };



    const getLikes = () => {
        let dialogId = dashboardDetail.dialog_id;
        let payload = {
            api_token: token,
            vendor_id: userDetail?.vendor_id,
            dialog_qb_id: dialogId,

        };
        axios.get("/qb/likes", { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setLikes(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    };

    const getLikeObj = (data) => {
        return likes.find((item) => item.message_qb_id === data.message._id);
    };

    const EnterPress = (e, submitForm) => {

        if (e.keyCode == 13 && (e.shiftKey == false)) {
            e.preventDefault();
            submitForm();
        }
    };

    return (
        <React.Fragment>
            <div className="col-lg-6  mt-2">
                {dashboardDetail.user_can_post && <Formik
                    initialValues={{
                        message: '',
                        file: null,
                    }}
                    onSubmit={(values, { resetForm }) => {
                        if (values.message.trim() !== "" || values.file !== null) {
                            setLoader(true);
                            sendMessage(values);
                            resetForm();
                        }
                    }} >
                    {({ setFieldValue, values, submitForm }) => (
                        <Form>
                            <div className="form-group card  card-radius mb-3">
                                <div className="card-body p-4">
                                    <div className="d-flex align-items-center">

                                        <div className="UplaodIm me-2">
                                            {imageUrl ? <img src={imageUrl} alt="" /> : <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/no-image.jpg"} alt="" />}
                                            <label htmlFor="file" onClick={(e) => { e.preventDefault(); fileRef?.current?.click(); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-camera"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" ></path><circle cx="12" cy="13" r="4"></circle></svg>
                                            </label>
                                            <input id="file" name="file" type="file" className=" border rounded my-3 col-8 p-1" ref={fileRef} onChange={(event) => {
                                                const file = event.currentTarget.files[0];
                                                var reader = new FileReader();

                                                reader.onloadend = function () {
                                                    console.log(reader.result);
                                                }
                                                reader.readAsDataURL(file);

                                                reader.onloadend = function () {
                                                    setImageUrl(reader.result);
                                                }

                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} />
                                        </div>

                                        <div className="flex-1">
                                            <Field type="textarea" as="textarea" className="form-control " id="message" name="message" placeholder="Say something..." onKeyDown={(e) => EnterPress(e, submitForm)} />
                                        </div>

                                        <div className="ps-2">
                                            {loader ? <Spinner animation="border" variant="primary" /> : <button type="submit" className="BtnSnd" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                            </button>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>}
                {loading ?
                    <div className=" text-center mt-2">
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    (chatdata && (chatdata.length > 0) ? <div className="col-12">
                        {dashboardDetail.dialog_id && chatdata && chatdata.map((data) => {
                            return <div className="card mb-4">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={data.user?.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="avatar-lg" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-0"><strong> {data.user?.display_name}</strong></h6>
                                            <p className="mb-0 text-muted"> {moment(data.message?.created_at).format('MMM D, YYYY [at] h:mm A z')}</p>
                                        </div>
                                        <div>
                                            {(data?.user?.id === userDetail?.id) && < div className="flex-end">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { setPopup(true); setMessageId(data.message._id); }}><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="ps-2 pe-2 pt-2">

                                        <div className='mb-3'> <Image data={data.message.attachments}></Image></div>
                                        <p className='font-18' dangerouslySetInnerHTML={{ __html: nl2br(data.message.message) }}></p>
                                        <div className="d-flex align-items-center mt-3 justify-content-between">
                                            {/* <a href="javascript:void(0);" className={`HeartLike ${getLikeObj(data)?.total_likes > 0 ? "active" : ""}`} >
                                                <div className="d-flex align-items-center ">
                                                    <div className="flex-shrink-0">
                                                        <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.5 302"><title>heart</title><path d="M-212.5,548c-5.1-3.8-10.4-7.2-15.2-11.3l-108.5-93.4c-21.1-18.1-34.4-40.8-39.4-68-6.2-34,.3-65.6,22.2-92.8s49.9-38.5,83.9-35.8c17.7,1.4,32.6,9.8,45.8,21.3,5,4.3,9.5,9.1,14.2,13.6,5.2-5,10.3-10.3,15.9-15.1,32.6-27.8,72-24.9,103.4-5.7,26.8,16.4,41.7,41.3,46.9,71.7,6.9,40.3-4.4,75.5-32.9,104.7-11.2,11.4-24,21.1-36.1,31.6l-84.1,72.3c-3.1,2.6-6.7,4.6-10.1,6.9Zm2.9-32.2,1.6-1.3,80.1-68.9c10.7-9.4,22.1-18.1,32.1-28.2,21.4-21.6,30-48,25.6-78.1-3.1-20.5-11.7-38.4-28.8-50.8-22.6-16.5-47-20-72.5-6.8-10.7,5.5-18.2,14.5-24.7,24.5a23.8,23.8,0,0,1-8.1,8c-6.4,3.4-13.5.5-17.7-6.5-6.7-11.1-14.9-20.9-26.7-26.7-25.5-12.5-49.8-8.8-71.8,7.9-19.6,14.8-28.1,35.9-29.2,59.9-1.4,29.1,9.1,53.7,31,72.9,25.4,22.3,51.3,44.2,76.9,66.3Z" transform="translate(377.8 -246)" fill="#c5c5c5" /></svg>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-0"><strong className={`${getLikeObj(data)?.total_likes > 0 && "red-color"}`}>  Likes</strong></h6>
                                                    </div>
                                                </div>
                                            </a> */}
                                            <a href="javascript:void(0);" className={`HeartLikeFull ${getLikeObj(data)?.liked_by_me ? "active" : ""}`} >
                                                <div className="d-flex align-items-center" onClick={() => likeMessage(data.message)}>
                                                    <div className="flex-shrink-0">
                                                        <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.5 302"><title>heart-full</title><path className="stroke" d="M-312.5,457c-5.1-3.8-10.4-7.2-15.2-11.3l-108.5-93.4c-21.1-18.1-34.4-40.8-39.4-68-6.2-34,.3-65.6,22.2-92.8s49.9-38.5,83.9-35.8c17.7,1.4,32.6,9.8,45.8,21.3,5,4.3,9.5,9.1,14.2,13.6,5.2-5,10.3-10.3,15.9-15.1,32.6-27.8,72-24.9,103.4-5.7,26.8,16.4,41.7,41.3,46.9,71.7,6.9,40.3-4.4,75.5-32.9,104.7-11.2,11.4-24,21.1-36.1,31.6l-84.1,72.3c-3.1,2.6-6.7,4.6-10.1,6.9Zm2.9-32.2,1.6-1.3,80.1-68.9c10.7-9.4,22.1-18.1,32.1-28.2,21.4-21.6,30-48,25.6-78.1-3.1-20.5-11.7-38.4-28.8-50.8-22.6-16.5-47-20-72.5-6.8-10.7,5.5-18.2,14.5-24.7,24.5a23.8,23.8,0,0,1-8.1,8c-6.4,3.4-13.5.5-17.7-6.5-6.7-11.1-14.9-20.9-26.7-26.7-25.5-12.5-49.8-8.8-71.8,7.9-19.6,14.8-28.1,35.9-29.2,59.9-1.4,29.1,9.1,53.7,31,72.9,25.4,22.3,51.3,44.2,76.9,66.3Z" transform="translate(477.8 -155)" /><path className="full" d="M-309.6,431.7l-34-29.3c-27.2-23.4-54.5-46.5-81.4-70.2-23.1-20.3-34.2-46.2-32.8-77,1.2-25.4,10.2-47.7,30.9-63.4,23.3-17.6,49-21.5,75.9-8.3,12.5,6.1,21.1,16.4,28.2,28.2,4.6,7.4,12,10.4,18.8,6.8,3.4-1.7,6.4-5.1,8.5-8.4,6.9-10.5,14.8-20.1,26.2-25.9,26.9-14,52.7-10.2,76.6,7.2,18.1,13.1,27.3,32.1,30.5,53.7,4.7,31.9-4.5,59.8-27,82.6-10.6,10.7-22.6,19.9-34,29.8l-84.7,73Z" transform="translate(477.8 -155)" /></svg>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-0"><strong>{getLikeObj(data)?.total_likes || 0} Like</strong></h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        })}
                    </div> : <div className="col-lg-6 text-center mt-5"><h4>No data found</h4></div>)}
            </div>
            {popup && <DeleteModal chatdata={chatMessage} setChatdata={(data) => setChatMessage(data)} QB={QB} popup={popup} setPopup={() => setPopup(false)} messageId={messageId} />}
        </React.Fragment >
    )
}